<template>
  <docs-layout>
    <div class="space-y-4 w-full text-left mb-4">
      <h1 class="text-3xl font-bold mt-8">Server Table </h1>
      <hr />
      <ol class="list-decimal list-inside">
        <li>The component AsomServerTable will take props 'url' for query's url, 'itemsPerPage' for parameter 'take', inner filtering value for  parameter 'query' and calculate the parameter 'skip' based on current page.</li>
        <li>Whenever user goes to next page, it would check data pool to if see a new data request is needed.</li>
        <li>Whenever user changes number of items per page, it would force the component to reset all data content.</li>
        <li>Whenever user changes filtering input, it would force the component to reset all data content.</li>
        <li>Filtering logic relies on throttle effect to avoid spamming requests to server.</li>
        <li>Prop 'retrieveData' is a function to override how data is retrieved from server. It will accept all parameters listed in 1st point</li>
        <li>After data is received from server, user can modify data before storing into component via prop 'processData'. By default, we use ASOMS list response format to proceed. The data must at least contains (1) list data and (2) total number of items before skip and take are applied.</li>
        <li>The component will expose 4 events: 'onRequestStarted', 'onRequestEnded', 'onDataRetrieved', 'onError' to capture data loading proceed.</li>
        <li>Due to limitation of backend API, we would not handle sorting for now.</li>
      </ol>
      <p>Example: loading data from <strong>{{defaultTable.url}}</strong></p>
      <asom-server-table
        :url="defaultTable.url"
        :columns="defaultTable.columns"
      >
        <template v-slot:no="slotScoped">
          {{slotScoped.rowIndex + 1}}
        </template>
        <template v-slot:role="slotScoped">
          {{slotScoped.rowData.roles && slotScoped.rowData.roles.join(', ')}}
        </template>
      </asom-server-table>
    </div>
  </docs-layout>
</template>
<script>
import { AUTH_API_HOST } from '@/constants/apis'
import DocsLayout from '@/docs/DocsLayout'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      defaultTable: {
        url: AUTH_API_HOST + '/auth/test-logins',
        columns: [ 'no', 'name', 'email', 'role' ]
      }
    }
  },
}
</script>

<style>

</style>